







import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import { equals, isNil } from 'ramda';

export default defineComponent({
    name: 'ParameterValidation',
    props: {
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: Object,
            default: () => {
                return {};
            },
        },
        changeDate: {
            type: Date,
            default: new Date(),
        },
        strict: {
            type: Boolean,
            default: false,
        },
    },
    components: { ValidationProvider },
    setup(props) {
        const validationRef = ref<any>(null);
        const hasError = computed(() => {
            if (props.changeDate && validationRef.value) {
                if (
                    validationRef.value.failedRules &&
                    Object.keys(validationRef.value.failedRules).length > 0 &&
                    ((!props.strict &&
                        Object.keys(validationRef.value.failedRules).filter((rule) => rule !== 'required').length >
                            0) ||
                        (props.strict && Object.keys(validationRef.value.failedRules).length > 0))
                ) {
                    return true;
                }
            }
            return false;
        });

        watch(
            () => props.rules,
            (newValue: any, oldValue: any) => {
                if (validationRef.value && (!equals(newValue, oldValue) || isNil(oldValue))) {
                    validationRef.value.validate();
                }
            },
            { immediate: true, deep: true },
        );

        watch(
            () => validationRef.value,
            (vRef: any) => {
                if (vRef) {
                    vRef.validate();
                }
            },
            { immediate: true },
        );

        watch(
            () => props.changeDate,
            () => {
                if (validationRef.value) {
                    validationRef.value.validate();
                }
            },
            { immediate: true },
        );

        const errorTextColour = computed(() => (hasError.value ? 'text-red-700' : 'text-orange-500'));

        return { validationRef, hasError, errorTextColour };
    },
});
